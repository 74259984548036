.home{
    //background-image: url('../../image/home-background.png') !important;
    height: 100vh;
    margin: auto !important;   
    .home-title{
        font-size: 1.625rem;
        color: #2E2E2E;
        margin-left: 3%;
    }
    .column{
        box-shadow: none !important;
    }
    .card{
        background-color: white;
        border-radius: 4px;
        border: 1px solid #D7D7D7;
        margin: 0px 5%;
        min-height: 42rem;
        .image{
            width: 100%;
            text-align: center;
            margin-top: 3.375rem;
            margin-bottom: 1.25rem;
            img{
                width: 6.25rem;
            }
        }
        .text{
            margin: 0px 14px 19px 14px;
            text-align: center;
            h2{
                font-weight: bold;
                color: #2E2E2E;
                line-height: 1.6875rem;
                font-size: 1.125rem;
            }
            span{
                font-weight: 400;
                color: #2E2E2E;
                line-height: 1.6875rem;
                font-size: 1.125rem;
            }
            .description{
                //text-align: left;
                height: 14rem;
                padding: 0px 5px;
                text-align: left;
            }
        }
        button{
            margin-top: 3.33rem;
            min-width: 10.625rem;
            font-size: 1.125rem;
            margin-bottom: 5.625rem;
            background: #51B0A1;
            border-radius: 2px;
            color: white;
            &:hover{
                background-color: #51B0A1;
                color: white;
            }
        }
    }
}
.ui.celled.grid{
    box-shadow: none !important;
}
.signOut{
    .out{

    }
    .email{
        font-size: 14px;
        opacity: 0.7;
    }
}
.ui.text.container{
    max-width: 1000px !important;
}
.home-container{
    .title{
        margin-bottom: 1.25rem;
        .titleIcon{
            width: 2px;
            height: 1rem;
            background-color: #2294BA;
            display: inline-block;
            border-radius: 4px;
            //margin-left: 1.25rem;
        }
        .titleText{
            margin-left: 0.5rem;
            font-weight: bold;
            color: #2E2E2E;
            font-size: 1.125rem;
        }   
        p{
            font-weight: 400;
            color: #2E2E2E;
            font-size: 1.125rem;
            line-height: 1.68rem;
        }     
    }

}
.narbar-menu {
    background: #0A223E !important;
    height: 3.75rem;
    a{
        font-size: 16px;
        font-weight: bold;
        padding: 1rem 1.125rem 0.875rem 1.125rem !important;
        &:hover{
            background-color: transparent !important;
            font-weight: bold !important;
        }
    }
    .active{
        background-color: transparent !important;
        font-weight: bold !important;
        &::after{
            content: ""; 
            position: absolute; 
            left: 15%; 
            bottom: 0; 
            width: 70%; 
            height: 1px; 
            border-bottom: 3px solid;
            border-image: linear-gradient(
                90deg, #51B0A1 0%, #2294BA 100%) 1;
        }
        //border-bottom: 3px solid #2294BA !important;
    }
}