.apiSideBar{
    background-color: #F5F7FA !important;
    box-shadow: none !important;
    .ui.inverted.menu .active.item{
            background-color: transparent !important;
            color: #2E2E2E !important;
            padding-right: 5px;
            padding-left: 5px !important;
    }
    .ui.inverted.menu .api-title{
        margin: 15px 0px !important;
        padding-left: 10px;
    }
    .ui.inverted.menu .item{
        padding: 13px 0px 14px 20px;
        font-size: 1rem;
        cursor: unset !important;
    }
    .ui.inverted.menu .second-menu{
        margin: 0em !important;
        .item{
            padding: 13px 0px 14px 20px !important;
            font-size: 0.929em !important;
            color: #333333 !important;
            display: flex;
            cursor: pointer !important;
            &:hover,&:focus{
                background-color: #DEE3EC !important;
            }
        }
        .get{
            padding: 2px auto;
            width: 25%;
            line-height: 20px;
            max-height: 20px !important;
            font-size: 0.929em;
            color: white;
            text-align: center;
            height: auto;
            border-radius: 4px;
            text-transform: uppercase;
            background-position: 6px 4px;
            margin-right: 10px;
        }
        .post{
            padding: 2px auto;
            width: 25% !important;
            line-height: 20px;
            max-height: 20px !important;
            font-size: 0.929em;
            color: white;
            text-align: center;
            height: auto;
            text-transform: uppercase;
            border-radius: 4px;
            background-position: 6px 4px;
            margin-right: 10px;
        }
    } 
    > .menu{
        background-color: #F5F7FA !important;
        .active{
            .item{
                color: black !important;
            }
        }
    }
}
.header-item{
    color: #333333 !important;
    padding: 12.5px 20px !important;
    margin: 15px 0px !important;
    display: flex;
    &:hover {
        background-color: #ededed !important;
        color: #2294BA !important;
    }
}
.sidebar-item{
    color: #333333 !important;
}

.opblock-summary{
    pointer-events: none;
}

.swagger-ui {
    .opblock.opblock-options{ display: none; }
    .opblock-tag{
        display: none;
    }
    .information-container{
        .header{
            color: #2294BA;
            font-weight: 500;
        }
        button{
            display: none;
            background: #2294BA;
            border-radius: 4px;
            color: white;
            width: 8.75rem;
            font-weight: 500;
            padding: 7px;
            font-size: 1.125rem;
            &:hover{
                background: #2294BA;
                border-radius: 4px;
                color: white;
                width: 140px;
                font-weight: 500;
            }
            &:focus{
                background: #2294BA;
                border-radius: 4px;
                color: white;
                width: 140px;
                font-weight: 500;
            }
        }
    }
    .scheme-container{
        background-color: white;
        border: none;
        padding: 10px 0px;
        box-shadow: none;
        .schemes{
            >label{
                margin-right: 0.625rem;
            }
            .schemes-title{
                font-weight: 500;
                color: #606266;
                margin-bottom: 0.625rem;
            }
            select{
                height: 2.1875rem;
                min-width: 110px !important;
                width: 8.75rem;
                font-weight: 400 !important;
                background: rgba(34, 148, 186, 0.06);
                border: 1px solid rgba(34, 148, 186, 0.5);
                box-shadow: none;
                border-radius: 4px;
                color: #2294BA;
                cursor: pointer;
            }
        }
        .auth-wrapper{
            flex: none;
            button{
                height: 2.1875rem;
                width: 8.75rem;
                min-width: 110px;
                margin-left: 1.875rem;
                max-height: 35px;
                padding: 1px 1px 1px 10px;
                font-weight: 500;
                background: rgba(34, 148, 186, 0.06);
                border: 1px solid rgba(34, 148, 186, 0.5);
                box-shadow: none;
                border-radius: 4px;
                color: #2294BA;
                span{
                    padding: 4px 1.25rem 0 0;
                }
                svg{
                    fill: #2294BA;
                    width: 1.25rem;
                    vertical-align: middle;
                }
            }
        }
    }
    .wapper{
        padding: 0px;
    }
    .opblock{
        margin: 0px;
        padding: 2.5rem 0px;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        .opblock-summary{
            display: flex !important;
            padding-bottom: 0.625rem;
            .opblock-summary-method{
                display: none !important;
            }
            .opblock-summary-path{
                background-color: white;
                display: none !important;
            }
            .opblock-summary-description{
                flex: none;
                font-weight: bold !important;
                font-size: 1.125rem !important;
                color: #2E2E2E !important;
            }
            .authorization__btn{
                svg{
                    width: 1.25rem;
                }
            }
            .authorization__btn{
                padding-bottom: 5px;
                opacity: 0.7;
            }
        }
        .opblock-body{
            grid-template-columns: 55% 45%;
            .opblock-description-wrapper{
                padding: 0.625rem 0px 0px 0px;
                .opblock-description{
                    font-size: 0.875rem !important;
                    pre{
                        border: none;
                    }
                }
            }
            .opblock-section{
                .opblock-section-header{
                    padding: 2.5rem 0px 0.4375rem 0px;
                    .opblock-title{
                        font-size: 1rem;
                        font-weight: 500;
                        color: #2E2E2E;
                        line-height: 1.5rem;
                    }
                    .try-out__btn{
                        border-radius: 4px;
                        border: 1px solid #2294BA;
                        color: white;
                        background-color: #2294BA;
                        font-weight: 500;
                        font-size: 0.875rem;
                        padding: 4px 1rem;
                    }
                    .cancel{
                        background-color: #51B0A1;
                        color: white;
                        border: 1px solid #51B0A1;
                    }
                }
                .table-container{
                    padding: 0.625rem 0px;
                    .parameters{
                        display: block;
                        box-sizing: unset !important;
                        >tbody{
                            display: block !important;
                            >tr{
                                display: block;
                                width: 100%;
                                .parameters-col_description{
                                    display: block !important;
                                    width: 100%;
                                }
                            }
                        }
                        textarea{
                            font-size: 0.875rem;
                            font-weight: 500;
                        }
                        pre{
                            font-size: 0.875rem;
                            font-weight: 500;
                        }
                        >thead >tr >th{
                            font-weight: 500;
                            color: #333333;
                            padding: 5px 0px;
                        }
                        >tbody >tr{
                            .parameters-col_name{
                                text-align: left;
                                // width: 30%;
                                padding-left: 0px;
                                padding-bottom: 0px;
                                display: flex;
                                .parameter__name{
                                    flex-direction: unset;
                                    font-weight: 400;
                                    font-size: 1rem;
                                    color: #606266;
                                    // flex-direction: row;
                                    &::after{
                                        // vertical-align: sub;
                                        // padding: 5px 10px 0px 10px;
                                        font-size: 0.8125rem;
                                        padding-top: 0px;
                                        top: 0px;
                                    }
                                }
                                .parameter__in{
                                    display: none;
                                }
                                .parameter__type{
                                    margin-left: 5px;
                                }
                            }
                            .parameters-col_description{
                                // width: 70%;
                                padding-left: 10px;
                                font-size: 0.875rem;
                                padding-top: 0px;
                                input{
                                    margin: 0px;
                                    padding: 0.5rem 0 0.5rem 0.625rem;
                                    &:focus-visible{
                                        outline-color: #2294BA;
                                    }
                                }
                                .tab{
                                    //display: none;
                                }
                                .model-box{
                                    overflow: auto;
                                    background-color: white;
                                    width: 100%;
                                    span{
                                        color: #3b4151;
                                        .model-hint{
                                            display: none;
                                        }
                                    }
                                    .model{
                                        margin-top: 1.25rem;
                                        //margin-left: -10%;
                                        tr{
                                            :first-child{
                                                padding-right: 0px !important;
                                                padding-left: 0px !important;
                                                color: #3b4151;
                                                font-size: 0.875rem;
                                            }
                                            :last-child{
                                                color: #3b4151;
                                                font-size: 0.875rem;
                                                .prop-type{
                                                    color: #C0C4CC !important
                                                }
                                                pre{
                                                    border: none;
                                                    
                                                    code{
                                                        background-color: white;
                                                        font-size: 0.875rem;
                                                        font-weight: 400;
                                                        color:#3b4151;
                                                        line-height: 1.4285em;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .btn-group{
                padding: 0px 30px;
                display: block;
                text-align: center;
                .btn{
                    width: 15%;
                    flex: none;
                    margin: 0px 20px;
                    border-radius: 4px;
                    box-shadow: none;
                    padding: 0.5rem 0;
                    font-weight: 500;
                    font-size: 0.875rem;
                }
                .execute{
                    background-color: #2294BA;
                    border: 1px solid #2294BA;
                }
                .btn-clear{
                    background-color: #51B0A1;
                    color: white;
                    border: 1px solid #51B0A1;
                }
            }
            .execute-wrapper{
                padding: 0px;
                text-align: center;
                button{
                    width: 15%;
                    padding: 0.5rem 0;
                    font-weight: 500;
                    font-size: 0.875rem;
                }
                .execute{
                    background-color: #2294BA;
                    border-color: #2294BA;
                }
            }
            .right-side-wrapper{
                background-color: rgba(34, 148, 186, 0.06);
                border-radius: 4px;
                .code-snippet{
                    li{
                        cursor: pointer;
                    }
                    margin: 10px;
                    .tabs-component-body{
                        .tabs-component-tabs{
                            background: transparent;
                            .tabs-component-tab{
                                a{
                                    color: #606266;  
                                    font-size: 0.9375rem;     
                                    padding-bottom: 2px;                            
                                }
                            }
                            .is-active{
                                a{
                                    color: #2294BA;
                                    padding-bottom: 2px;
                                    border-bottom: 2px solid #2294BA; 
                                    font-size: 0.9375rem;
                                }
                                
                            }
                        }
                        .tabs-component-panels{
                            pre{
                                background-color: #2E2E2E;
                                color:  #F5F7FA;
                                font-weight: 400;
                            }
                            
                        }
                    }
                    
                }
                .responses-wrapper{
                    .opblock-section-header{
                        h4{
                            font-size: 1rem;
                            font-weight: 400;
                            margin-bottom: 10px;
                            color: #606266;
                        }
                        background-color: transparent;
                        padding: 5px 10px;
                        display: block;
                        >label{
                            display: block;
                            span{
                                display: none;
                            }
                            select{
                                width: 100%;
                                color: #2294BA;
                            }
                        }
                        select{
                            font-weight: 400;
                            border: 1px solid rgba(34, 148, 186, 0.5);
                            box-shadow: none;
                            background-color: rgba(34, 148, 186, 0.06);
                        }
                    }
                    .responses-inner{
                        .request-url{
                            padding: 0px 10px;
                        }
                        pre{
                            background-color: #2E2E2E;
                            color:  #F5F7FA;
                            font-weight: 400;
                            font-size: 0.75rem;
                            padding-left: 10px;
                            border: none;
                        }
                        .example{
                            padding: 0;
                        }
                        .responses-table{
                            .response{
                                padding: 0px 10px;
                                .response-col_description{
                                    background-color: #2E2E2E;
                                    color:  #F5F7FA;
                                    font-weight: 400;
                                    font-size: 0.75rem;
                                    padding: 10px 10px;
                                    border-radius: 0px;
                                    .response-col_description__inner{
                                        .markdown{
                                            background-color: #2E2E2E;
                                            font-size: 0.875rem;
                                            font-style: normal;
                                            padding: 0;
                                            color:  #F5F7FA;
                                            font-weight: 400;                                          
                                        }
                                    }
                                    .headers-wrapper{
                                        .headers{
                                            .header-col{
                                                color: #F5F7FA;
                                                font-weight: 500;
                                                padding: 0.5rem;
                                            }
                                            
                                        }
                                    }
                                    .tab{
                                        margin: 10px 0;
                                        .tabitem{
                                            //display: none;
                                            //pointer-events: none;
                                            font-weight: 500;
                                        }
                                        .active{
                                            display: block;
                                            &::after{
                                                //display: none;
                                            }
                                        }
                                    }
                                    .model-title{
                                        font-size: 0.75rem;
                                        .model-hint{
                                            display: none;
                                        }
                                    }
                                    .model{
                                        font-size: 0.75rem;
                                    }
                                }       
                                .response-col_status{
                                    .response-undocumented{
                                        padding-left: 10px;
                                    }
                                    background-color: #2E2E2E;
                                    padding: 10px 10px;
                                    color:  #F5F7FA;
                                    font-weight: 400;
                                    font-size: 0.875rem;
                                    i{
                                        color:  #F5F7FA;
                                        font-weight: 400;
                                        font-size: 0.75rem;
                                        font-style: inherit;
                                    }
                                }                         
                            }

                        }
                        .live-responses-table{
                            .response{
                                .response-col_description{
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                }
                .url{
                    margin-bottom: 10px;
                    display: flex;
                    padding: 0.5rem 0.625rem;
                    background: rgba(34, 148, 186, 0.06);
                    border-radius: 4px;
                    border: 1px solid rgba(34, 148, 186, 0.5);
                    color: #2294BA !important;
                    .method{
                        text-align: left;
                        color: #ffffff;
                        line-height: 20px;
                        padding: 2px 14px;
                        border-radius: 4px;
                        font-size: 0.8125rem;
                        text-transform: uppercase;
                    }
                    .get{
                        background: #2294BA;
                    }
                    .post{
                        background-color: #51B0A1;
                    }
                    .options{
                        background-color: #0d5aa7;
                    }
                    .delete{
                        background-color: #CF3030;
                    }
                    .put{
                        background-color: #E97500;
                    }
                    .head{
                        background-color: #9012fe;
                    }
                    .patch{
                        background-color: #00A0A7;
                    }
                    .path{
                        margin-top: 2px;
                        margin-left: 10px;
                        flex: 1;
                        line-height: 20px;
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }
    .download-contents{
        display: none;
        font-weight: 500;
        padding: 0.25rem 0.625rem;
        height: auto;
        width: auto;
        bottom: 0px;
        font-size: 0.875rem;
    }
    .dialog-ux{
        .backdrop-ux{
            background: #051324;
            opacity: 0.59;
            height: 100vh;
        }
        .modal-ux{
            border-radius: 20px;
            max-width: 32.5rem;
            .modal-ux-header{
                button{
                    display: none;
                }
                h3{
                    font-size: 1.375rem;
                    text-align: center;
                    padding: 10px;
                }
            }
            .modal-ux-content{
                padding: 1.8125rem 1.875rem 1.5rem 1.875rem;
                .auth-container{
                    padding: 0;
                    h4{
                        margin: 5px 0px !important;
                        display: inline-block;
                        font-weight: 500;
                        color: #2E2E2E;
                        font-size: 1.25rem;
                    }
                    h6{
                        display: inline-block;
                        vertical-align: middle;
                        border-radius: 4px;
                        border: 1px solid #2294BA;
                        width: 84px;
                        text-align: center;
                        font-weight: 400;
                        color: #2294BA;
                        margin: 0px 10px;
                        font-size: 0.875rem;
                    }
                    .wrapper{
                        margin: 6px 0px;
                        p{
                            font-weight: 400;
                            color: #606266;
                            font-size: 1.125rem !important;
                        }
                        label{
                            font-weight: 400;
                            color: #606266;
                            font-size: 1.125rem !important;
                        }
                        section{
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                }
                .auth-btn-wrapper{
                    padding-top: 1.25rem;
                    padding-bottom: 0;
                    button{
                        height: 3.125rem !important;
                        font-size: 1.3rem;
                        width: 50%;
                        margin: 0px 0.9375rem;
                        background-color: #2294BA;
                        color: white;
                    }
                    .btn-done{
                        color: white !important;
                        background: #51B0A1 !important;
                    }
                }
                input{
                    &:focus-visible{
                        outline: 1px solid #89C6DA;
                    }
                }
            }
        }
    }
    .model-box{
        background-color: #2E2E2E;
        span{
            font-weight: 500 !important;
            color: white;
        }
        .model{
            .model-toggle{
                &::after{}
            }
            >tbody >tr >td {
                width: auto !important;
                padding: 0px 1rem;
                font-weight: 500;
                color: white;
            }
        }
    }
    .models{
        display: none;
    }
}

.dialog-ux{
    left: unset !important;
    width: 100vw;
}

.backdrop-ux{
    position: fixed !important;
    right: 0;
    width: 100vw;
    top: 0;
    background-color: #051324 !important;
    opacity: 0.59;
    left: unset !important;
    bottom: unset !important;
}